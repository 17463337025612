import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl'; // import mapbox api
import './Content1.css'; // css styling for map container
import MapboxDraw from '@mapbox/mapbox-gl-draw' // imports polygon drawing
import "mapbox-gl/dist/mapbox-gl.css"; // imports mapbox button icons for fullscreen,zoom,gps
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"; // import mapbox button icons for draw polygon
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'; // search bar for map at top right
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'; // styling for search bar
import updateMarkers from './PolygonFiltering';

mapboxgl.accessToken =
  'pk.eyJ1IjoiZi1hbHZhcmV6cGVuYXRlIiwiYSI6ImNsbTQ0eHp2YzNwb3czbW8yN3kyMTQwNXIifQ.i1tZc88cTP3Ci1-i0K7LCQ'; // flavio's access token

  // Polygon drawing functionality
const draw = new MapboxDraw({
  displayControlsDefault: false,
  // Select which mapbox-gl-draw control buttons to add to the map.
  controls: {
      polygon: true,
      trash: true
  }
});

const allMarkers = [];

const Content1 = () => {
  const mapContainerRef = useRef(null);
  const [lng, setLng] = useState(-117.181738);
  const [lat, setLat] = useState(46.729777);
  const [zoom, setZoom] = useState(9);

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom
    });

    // Update lat,long,zoom sidebar
    map.on('move', () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
    });

    // Given a query in the form "lng, lat" or "lat, lng"
    // returns the matching geographic coordinate(s)
    const coordinatesGeocoder = function (query) {

      // Match anything which looks like
      // decimal degrees coordinate pair.
      const matches = query.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i);
      if (!matches) {
          return null;
      }

      function coordinateFeature(lng, lat) {
          return {
              center: [lng, lat],
              geometry: {
                  type: 'Point',
                  coordinates: [lng, lat]
              },

              place_name: 'Lat: ' + lat + ' Lng: ' + lng,
              place_type: ['coordinate'],
              properties: {},
              type: 'Feature'
          };
      }

      const coord1 = Number(matches[1]);
      const coord2 = Number(matches[2]);
      const geocodes = [];

      if (coord1 < -90 || coord1 > 90) {
          // must be lng, lat
          geocodes.push(coordinateFeature(coord1, coord2));
      }

      if (coord2 < -90 || coord2 > 90) {
          // must be lat, lng
          geocodes.push(coordinateFeature(coord2, coord1));
      }

      if (geocodes.length === 0) {
          // else could be either lng, lat or lat, lng
          geocodes.push(coordinateFeature(coord1, coord2));
          geocodes.push(coordinateFeature(coord2, coord1));
      }
          return geocodes;
      };

      // Search
      map.addControl(
      new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          localGeocoder: coordinatesGeocoder,
          // zoom: 6,
          placeholder: 'Address or LAT, LONG',
          mapboxgl: mapboxgl,
          reverseGeocode: true,
          marker:{
              color: 'green'
          }
      })
    );
    
    map.addControl(draw);
    
    // updateMarkers() will be the function for polygon filtering
    map.on('draw.create', updateMarkers);
    map.on('draw.delete', updateMarkers);
    map.on('draw.update', updateMarkers);
    
    // Add full screen function to the map
    map.addControl(new mapboxgl.FullscreenControl(), 'top-left');
    
    // Add zoom function to the map
    map.addControl(new mapboxgl.NavigationControl(), 'top-left');
    
    // Add current location (user's)
    map.addControl(
        new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
    
            // When active the map will receive updates to the device's location as it changes.
            trackUserLocation: true,
            // Draw an arrow next to the location dot to indicate which direction the device is heading.
            showUserHeading: true
            }), 'top-left'
    );
    
    // load test marker data
    fetch('https://api.mapbox.com/datasets/v1/f-alvarezpenate/clgpynyxk0sab2ao5sbuv0xxx/features?access_token=pk.eyJ1IjoiZi1hbHZhcmV6cGVuYXRlIiwiYSI6ImNsZWh0ZXB6cTB5YnIzcW16NTJ5OGpvbGMifQ.0e-hIYt1VVSoRXujuzJnMA')
      .then(response => response.json())
      .then(data => {
          // Assign the parsed GeoJSON data to a variable
          for (let i = 0; i < data.features.length; i++){
            const feature = data.features[i];
            
            const el = document.createElement('div');
            el.className = 'marker';

            // make a marker for each feature and add it to the map
            var x = new mapboxgl.Marker(el)
            x.setLngLat(feature.geometry.coordinates)
            x.setPopup(
                new mapboxgl.Popup({ offset: 25 }) // add popups
                .setHTML( //Attach popups to markers
                    `<h3>${feature.properties.title}</h3><p>${feature.properties.description}</p>`
                )
            )
            x.addTo(map);
            allMarkers.push([feature.properties.category, feature.geometry.coordinates])
          }
       }
      );
    // github link to urban data: https://github.com/f-alvarezpenate/MarkerDataLivingAtlas/blob/main/Washington_State_City_Urban_Growth_Areas.geojson
    
    // Now we fetch the data from github repo using GitHub Raw
    // Github raw allows us to fetch raw data file without having additional html or formatting added by github
    fetch('https://raw.githubusercontent.com/f-alvarezpenate/MarkerDataLivingAtlas/main/Washington_State_City_Urban_Growth_Areas.geojson')
        .then(response => response.json())
        .then(data => {
            // Add the GeoJSON data as a fill layer to the map
            map.addSource('urban-areas', {
                type: 'geojson',
                data: data,
            });

            // Add the urban area layer
            map.addLayer({
                id: 'urban-areas-fill',
                type: 'fill',
                source: 'urban-areas',
                paint: {
                    'fill-color': 'red', // Set the fill color to red
                    'fill-opacity': 0.4, // Adjust the opacity if needed
                },
            });

            // Outline the urban area
            map.addLayer({
                id: 'urban-areas-outline',
                type: 'line',
                source: 'urban-areas',
                paint: {
                    'line-color': 'white', // Set the outline color
                    'line-width': 1, 
                },
            });

            // Add event listener
            map.on('click', 'urban-areas-fill', (e) => {
              const features = map.queryRenderedFeatures(e.point, {
                  layers: ['urban-areas-fill']
              });
      
              if (!features.length) {
                  return;
              }
              const feature = features[0];
              // We display a pop for the important description of the urban area
              new mapboxgl.Popup({ offset: 100 })
                  .setLngLat(e.lngLat)
                  .setHTML(`
                              <h3><strong>${feature.properties.CITY_NM}</h3></strong>
                              <ul><strong>OBJECTID:</strong> ${feature.properties.OBJECTID}</ul>
                              <ul><strong>UGA_NM:</strong> ${feature.properties.UGA_NM}</ul>
                              <ul><strong>UGA_NM2:</strong> ${feature.properties.UGA_NM2}</ul>
                              <ul><strong>COUNTY_NM:</strong> ${feature.properties.COUNTY_NM}</ul>
                              <ul><strong>GMA:</strong> ${feature.properties.GMA}</ul>
                              <ul><strong>FIPS_PLC:</strong> ${feature.properties.FIPS_PLC}</ul>
                              <ul><strong>INCORP:</strong> ${feature.properties.INCORP}</ul>
                              <ul><strong>ORIGIN:</strong> ${feature.properties.ORIGIN}</ul>
                              <ul><strong>DATEMOD:</strong> ${feature.properties.DATEMOD}</ul>
                          `)
                  .addTo(map);
          });
      
          //  We change the cursor to a pointer when hovering over urban areas
          map.on('mouseenter', 'urban-areas-fill', () => {
              map.getCanvas().style.cursor = 'pointer';
          });
      
          // We change the cursor back to the default if not hover over
          map.on('mouseleave', 'urban-areas-fill', () => {
              map.getCanvas().style.cursor = '';
          });
        })
      
        .catch(error => {
            console.error('Error fetching urban area data:', error);
        });
        
        // Load river stream data from using vector tileset from mapbox studio
        map.on('load', function () {
          map.addLayer({
            id: 'vector-tileset',
            type: 'fill',
            source: {
              type: 'vector',
              // Tileset URL or ID from mapbox studio
              url: 'mapbox://phearakboth.3q578f33', 
            },
            // Get the source layer name from tileset on mapbox studio
            'source-layer': 'NHD_streams-6uidcp', 
            paint: {
              'fill-color': 'blue', 
              'fill-opacity': 0.5, 
            },
          });
        });
    // // Clean up on unmount
    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <div>
        <head>
            <link rel="stylesheet" href="https://api.tiles.mapbox.com/mapbox-gl-js/v1.5.1/mapbox-gl.css" type="text/css" />
        </head>
      <div className='sidebarStyle'>
        <div>
          Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
        </div>
      </div>
      <div className='map-container' ref={mapContainerRef} />
    </div>
    
  );

};

export {allMarkers, draw};
export default Content1;


// import React from 'react';
// import './Content1.css';


// function Content1() {
//     return (
//         <section id="content-1">
//             <h1>Content Area 1</h1>
//             <p>
//                 Below is a map that shows all the data points in our system. Each marker represents a unique data point, and you can click on each marker to view more information about that point.
//             </p>
//         </section>
//     );
// }

// export default Content1;
